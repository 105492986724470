<template>
    <LCircle
        v-if="hasNetworkData"
        :lat-lng="latLng"
        :radius="40"
        :fill="true"
        :fill-color="rssiColor"
        :fill-opacity="0.7"
        :stroke="false"
    />
</template>

<script>
import { LCircle } from 'vue2-leaflet'

export default {
    name: 'HeatmapMarker',
    components: {
        LCircle,
    },
    props: {
        location: {
            type: Object,
            required: true,
        },
    },
    computed: {
        latLng() {
            return [this.location.lat, this.location.lng]
        },
        hasNetworkData() {
            return (
                !!this.location?.network_data?.rssi &&
                !!this.location?.network_data?.snr
            )
        },
        rssiColor() {
            switch (this.location.network_data.gsm_signal) {
                case 5:
                    return '#41b883'
                case 4:
                    return '#fbfb43'
                case 3:
                    return '#fdae61'
                case 2:
                case 1:
                case 0:
                    return '#d32f2f'
            }

            const esp =
                this.location.network_data.rssi -
                10 *
                    Math.log(
                        1 + Math.pow(10, -this.location.network_data.snr / 10)
                    )

            if (esp < -110) {
                return '#d32f2f'
            } else if (esp < -100) {
                return '#fdae61'
            } else {
                return '#41b883'
            }
        },
    },
}
</script>

<style lang="scss">
.heatmap-marker {
    background-color: $color-text;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.7;
}

.heatmap-marker-low {
    background-color: $color-red;
}

.heatmap-marker-medium {
    background-color: $color-orange;
}

.heatmap-marker-high {
    background-color: $color-green;
}
</style>
