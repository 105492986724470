<template>
    <LFeatureGroup>
        <HeatmapMarker
            v-for="(location, key, index) in measurements"
            :key="index"
            :location="location"
        />
    </LFeatureGroup>
</template>

<script>
import { LFeatureGroup } from 'vue2-leaflet'

import HeatmapMarker from './HeatmapMarker'

export default {
    name: 'ConnectionHeatmapLayer',
    components: {
        HeatmapMarker,
        LFeatureGroup,
    },
    props: {
        measurements: {
            type: Array,
            required: true,
        },
    },
    computed: {
        allCoordinates() {
            return this.measurements.map(marker => {
                return [marker.lat, marker.lng]
            })
        },
    },
    watch: {
        measurements(measurements) {
            if (measurements) {
                this.$emit('alignMap', this.allCoordinates)
            }
        },
    },
}
</script>
